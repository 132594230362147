import {
  IAnswer,
  IInterview,
  IOptimize,
} from "src/modules/interview/interface/interview.type";
import { Api } from "./Api";

export class InterviewService extends Api<IInterview | FormData> {
  constructor() {
    super("/interviews");
  }

  async createInterview(payload: Omit<IInterview, "participants">) {
    return this.post(payload);
  }

  async updateInterview(id: string, payload: IInterview) {
    return this.put(id, payload);
  }

  async sendInvite(id: number, payload: Pick<IInterview, "participants">) {
    return this.post(payload, `${id}/invite-participants`);
  }

  async deleteInterview(id: number) {
    return this.delete(`${id}`);
  }

  async getInterview(id: number) {
    return this.get(`${id}`);
  }
  async startInterView(id: number) {
    return this.post({}, `preview/${id}/start`);
  }

  async postInterViewAnswer(payload: IAnswer) {
    const { interviewId, formData } = payload;
    if (!formData) {
      return;
    }
    return this.post(formData, `preview/${interviewId}/answer`);
  }

  async optimizeInterView(payload: IOptimize) {
    return this.get(`optimize?type=${payload.type}&text=${payload.text}`);
  }

  async getInterviewCandidates(id: number) {
    return this.get(`${id}/participants`);
  }
  async getCandidatesDetails(id: number) {
    return this.get(`${id}`);
  }
  async getTopInterviewParticipants(id: number) {
    return this.get(`${id}/top-participants`);
  }
}
